// src/components/Services.js
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function Services() {
  return (
    <section id="services" className="py-5">
      <div className="container">
        <h2 className="text-center mb-5">Our Services</h2>
        <div className="row">
          <div className="col-md-4">
            <div className="service-item p-4 bg-light rounded shadow-sm">
              <h3>Software Engineering</h3>
              <p>
                We design, develop, and maintain software solutions tailored to
                your business needs using the latest technologies and
                methodologies.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="service-item p-4 bg-light rounded shadow-sm">
              <h3>IT Consultancy</h3>
              <p>
                We offer expert IT consulting services to help businesses
                optimize their technology strategies, infrastructure, and
                operations.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="service-item p-4 bg-light rounded shadow-sm">
              <h3>Cybersecurity</h3>
              <p>
                Our cybersecurity services provide robust protection for your
                systems and data, ensuring that your business stays safe from
                digital threats.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
