// src/components/About.js
import React from "react";
import "./About.css";

function About() {
  return (
    <section id="about">
      <h2>About PyPanther</h2>
      <p>
        PyPanther is an emerging tech start-up with a bold focus on software
        engineering, IT consultancy, and cybersecurity. Though new on the scene,
        our team brings fresh perspectives and deep expertise to help businesses
        navigate the ever-evolving tech landscape. We're driven by innovation
        and the desire to solve complex problems with cutting-edge solutions. At
        PyPanther, we're committed to building scalable, secure systems that
        empower our clients to grow and succeed in the digital age.
      </p>
    </section>
  );
}

export default About;
