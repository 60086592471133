// src/components/Home.js
import React from "react";
import "./Home.css";

function Home() {
  return (
    <section id="home">
      <h2>Welcome to PyPanther</h2>
      <p>
        At PyPanther, we're passionate about creating intelligent software and
        AI-driven solutions that push boundaries and accelerate innovation. Our
        mission is to help businesses unlock new possibilities, optimize their
        operations, and stay ahead in the rapidly evolving digital landscape.
        Whether it's building custom software or leveraging AI to solve complex
        challenges, we're here to empower your growth in the age of intelligent
        technology.
      </p>
    </section>
  );
}

export default Home;
