// src/components/Footer.js
import React from "react";

function Footer() {
  return (
    <footer>
      <p>&copy; 2024 PyPanther LLC - All Rights Reserved</p>
      <p>
        Website:{" "}
        <a href="http://pypanther.com" style={{ color: "white" }}>
          pypanther.com
        </a>
      </p>
    </footer>
  );
}

export default Footer;
