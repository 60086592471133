// src/components/Header.js
import React from "react";
import logo from "../assets/logo.png"; // Ensure to place the logo in the public/assets folder
import "./Header.css";

function Header() {
  return (
    <header>
      <div className="logo">
        <a href="#home">
          <img src={logo} alt="PyPanther Logo" />
        </a>
      </div>
      <h1>PyPanther</h1>
      <nav>
        <a href="#home">Home</a>
        <a href="#about">About</a>
        <a href="#services">Services</a>
        <a href="#contact">Contact</a>
      </nav>
    </header>
  );
}

export default Header;
