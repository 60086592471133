// src/components/Contact.js
import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <section id="contact">
      <h2>Contact Us</h2>
      <p>
        Have a question or need more information? Feel free to reach out to us
        using the contact form below:
      </p>
      <div className="contact-form">
        <form
          name="contact"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
          onSubmit="submit"
        >
          <input type="hidden" name="form-name" value="contact" />
          <p className="hidden">
            <label>
              Don’t fill this out if you're human:
              <input name="bot-field" />
            </label>
          </p>
          <input type="text" name="name" placeholder="Your Name" required />
          <input type="email" name="email" placeholder="Your Email" required />

          <textarea
            name="message"
            rows="5"
            placeholder="Your Message"
            required
          ></textarea>
          <button type="submit">Send Message</button>
        </form>
      </div>
    </section>
  );
}

export default Contact;
